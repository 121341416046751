import React from 'react';
import { useNavigate } from 'react-router-dom';
import MenuButton from '@doctoroncallcom/dermis-web/dist/esm/MenuButton';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import * as redux from '@doctoroncallcom/redux';
import URL from '../../utils/url';
import { SSO_LOGOUT_URL } from '../../config/constants';
import routes from '../../config/routes';
import './style.css';

const classNames = require('classnames');

const Header = ({ toggleMenu, showHeader }) => {
  const navigate = useNavigate();

  const navigateToLink = (url) => {
    navigate(url);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = new URL(SSO_LOGOUT_URL).getUrl();
  };

  return (
    <div
      className={classNames('h-screen fixed menu_con', {
        'menu-backdrop-show': showHeader,
        'menu-backdrop-hide': !showHeader,
      })}
    >
      <div className="backdrop" onClick={toggleMenu}>
        &nbsp;
      </div>
      <div
        className={classNames('w-full h-screen fixed z-10 header', {
          'header-show': showHeader,
          'header-hide': !showHeader,
        })}
      >
        <div className="p-10">
          <img src="/assets/icons/DOC-LOGO.svg" alt="DoC Logo" width="100px" />
        </div>
        <div className="py-6 menu_con z-10 h-full overflow-y-auto scrollbar-hide pb-52">
          <nav>
            <MenuButton
              label="Dashboard"
              type="button"
              normal={window.location.pathname !== routes.dashboard}
              href={routes.dashboard}
              width="100%"
              height="56px"
              icon="dashboard"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.dashboard)}
            />
            {/* <MenuButton
              label="Manual Case"
              type="button"
              normal={window.location.pathname !== routes.manualCases}
              href={routes.manualCases}
              width="100%"
              height="56px"
              icon="add-icon"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.manualCases)}
            /> */}
            {/* <MenuButton
              label="Specialities"
              type="button"
              normal={window.location.pathname !== routes.categories}
              href={routes.categories}
              width="100%"
              height="56px"
              icon="categories"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.categories)}
            /> */}
            <MenuButton
              label="Consultations"
              type="button"
              normal={window.location.pathname !== routes.consultationHistory}
              href={routes.consultationHistory}
              width="100%"
              height="56px"
              icon="customers"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.consultationHistory)}
            />
            {/* <MenuButton
              label="Hospital"
              type="button"
              normal={window.location.pathname !== routes.hospital}
              href={routes.hospital}
              width="100%"
              height="56px"
              icon="hospital"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.hospital)}
            /> */}
            <MenuButton
              label="Doctors"
              type="button"
              normal={window.location.pathname !== routes.doctors}
              href={routes.doctors}
              width="100%"
              height="56px"
              icon="doctors"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.doctors)}
            />
            {/* <MenuButton
              label="Prescription"
              type="button"
              normal={window.location.pathname !== routes.prescriptions}
              href={routes.prescriptions}
              width="100%"
              height="56px"
              icon="prescription"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.prescriptions)}
            /> */}
            {/* <MenuButton
              label="Scripts Plans"
              type="button"
              normal={window.location.pathname !== routes.scriptsPlans}
              href={routes.scriptsPlans}
              width="100%"
              height="56px"
              icon="hospital"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.scriptsPlans)}
            /> */}
            {/* <MenuButton
              label="My Profile"
              type="button"
              normal={window.location.pathname !== routes.profile}
              href={routes.profile}
              width="100%"
              height="56px"
              icon="profile"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.profile)}
            /> */}
            {/* <MenuButton
              label="Users"
              type="button"
              normal={window.location.pathname !== routes.users}
              href={routes.users}
              width="100%"
              height="56px"
              icon="users"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.users)}
            /> */}
            {/* <MenuButton
              label="Users and permissions"
              type="button"
              normal={window.location.pathname !== routes.usersAndPermission}
              href={routes.usersAndPermission}
              width="100%"
              height="56px"
              icon="users"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.usersAndPermission)}
            /> */}
            {/* <MenuButton
              label="Settings"
              type="button"
              normal={window.location.pathname !== routes.settings}
              href={routes.settings}
              width="100%"
              height="56px"
              icon="settings"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.settings)}
            /> */}
            <MenuButton
              label="Log Out"
              type="button"
              normal={true}
              href="#"
              width="100%"
              height="56px"
              icon="log-out"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={handleLogout}
            />
          </nav>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  toggleMenu: PropTypes.func,
  showHeader: PropTypes.bool,
};

Header.defaultProps = {
  toggleMenu: undefined,
  showHeader: false,
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Header);
